type ClientInfo = {
  current_url?: string;
  client_device?: string;
  client_browser?: string;
};

const getBrowserName = () => {
  let browserInfo = navigator.userAgent;
  let browser;
  if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
    browser = "Opera";
  } else if (browserInfo.includes("Edg")) {
    browser = "Edge";
  } else if (browserInfo.includes("Chrome")) {
    browser = "Chrome";
  } else if (browserInfo.includes("Safari")) {
    browser = "Safari";
  } else if (browserInfo.includes("Firefox")) {
    browser = "Firefox";
  } else {
    browser = "unknown";
  }
  return browser;
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

function useClientInfo(): ClientInfo {
  const clientInfo: ClientInfo = {};
  if (location?.href) {
    clientInfo.current_url = location.href;
  }
  if (navigator?.userAgent) {
    clientInfo.client_browser = getBrowserName();
  }
  if (navigator?.userAgent) {
    clientInfo.client_device = getDeviceType();
  }
  return clientInfo as ClientInfo;
}

export default useClientInfo;

import { errorsToObject } from "lib/helpers";
import { useState } from "react";
import axo from "src/utils/axo";
import isEmpty from "lodash/isEmpty";
import { useAppDispatch } from "store/hooks";
import { setUserName } from "store/reducers/formSlice";
import router from "next/router";
import useUtmParams from "./useUtmParams";
import useClientInfo from "./useClientInfo";

function useFormSubmit(afterSubmit = null) {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const utmParams = useUtmParams();
  const clientInfo = useClientInfo();

  const [alertType, setAlertType] = useState<
    "success" | "warning" | "info" | "error"
  >("success");

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleFormSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const response = await axo.post("/enquiry-details", {
        ...values,
        ...utmParams,
        ...clientInfo,
      });
      setLoading(false);
      actions.resetForm();
      if (response.status === 200) {
        setMessage(response.data.message);
        setAlertType("success");
        setAlertOpen(true);
        dispatch(
          setUserName(
            values.fullName
              ? values.fullName
              : values.firstName + " " + values.lastName
          )
        );
        if (typeof afterSubmit === "function") {
          afterSubmit();
        }
        router.push("/thank-you-for-enquiry");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        const { errors, message } = error.response.data;
        if (message) {
          setMessage(message), setAlertType("error");
          setAlertOpen(true);
        }
        if (error.response.status === 422 && !isEmpty(errors)) {
          const modifiedErrors = errorsToObject(errors);
          actions.setErrors(modifiedErrors);
        } else {
          console.warn(message);
        }
      }
    }
  };

  return {
    handleFormSubmit,
    alertOpen,
    handleAlertClose,
    message,
    alertType,
    loading,
  };
}

export default useFormSubmit;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import snakeCase from "lodash/snakeCase";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingRight: theme.spacing(1),
    },

    dialogTitle: {
      fontSize: 20,
      fontWeight: 500,
    },
    dialogContent: {
      paddingBottom: theme.spacing(3),
    },
  })
);

interface FormDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subTitle?: string;
  children: React.ReactChild;
}

const FormDialog: React.FC<FormDialogProps> = ({
  open,
  onClose,
  title,
  subTitle,
  children,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`${snakeCase(title)}-form-dialog-title`}
    >
      <DialogTitle
        id={`${snakeCase(title)}-form-dialog-title`}
        disableTypography
        className={classes.dialogTitleContainer}
      >
        <Typography className={classes.dialogTitle}>{title}</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {subTitle && <DialogContentText>{subTitle}</DialogContentText>}
        {children}
      </DialogContent>
    </Dialog>
  );
};
export default React.memo(FormDialog);

import React, { useCallback } from "react";
import { fieldToTextField } from "formik-material-ui";
import dynamic from "next/dynamic";
import { getCookie, hasCookie } from "cookies-next";
import { useRouter } from "next/router";
const MuiPhoneNumber = dynamic(() => import("material-ui-phone-number"), {
  ssr: false,
});
function PhoneInput(props) {
  const router = useRouter();
  const { country } = router?.query;
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = useCallback(
    (value) => {
      setFieldValue(name, value ? value : "");
    },
    [setFieldValue, name]
  );
  return (
    <MuiPhoneNumber
      {...fieldToTextField(props)}
      onChange={onChange}
      disableAreaCodes={true}
      disableDropdown={false}
      autoFormat={false}
      disableCountryCode={false}
      countryCodeEditable={false}
      excludeCountries={["pk", "ua", "ru", "mv"]}
      defaultCountry={
        country
          ? country
          : hasCookie("geoLocation")
          ? getCookie("geoLocation")
          : "us"
      }
    />
  );
}

export default PhoneInput;

import { useRouter } from "next/router";
import pick from "lodash/pick";

type UtmDataType = {
  utm_source: string;
  utm_medium: string;
  utm_term: string;
  utm_content: string;
  utm_device: string;
  utm_campaign: string;
  utm_adgroup: string;
  utm_location: string;
  utm_channel: string;
  utm_variety: string;
  gclid: string;
};

function useUtmParams(): UtmDataType {
  const router = useRouter();
  const pickOnly = [
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_content",
    "utm_device",
    "utm_campaign",
    "utm_adgroup",
    "utm_location",
    "utm_channel",
    "utm_variety",
    "gclid",
  ];
  const utmParams = pick(router.query, pickOnly);
  return utmParams as UtmDataType;
}
export default useUtmParams;
